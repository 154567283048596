.laddaButton_EXPAND_LEFT{
  padding-left: $spacer;
  padding-right: $spacer;
}


.custom-ui {
  text-align: center;
  width: 500px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
}

.custom-ui > h1 {
  margin-top: 0;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.react-confirm-alert-overlay{
  z-index: 9999;
}

.rsdt.rsdt-column-toggles.toggle{
  padding: 10px;
  label{
    margin: 0;
  }
  input{
    margin-right: 5px;
  }
  label[for=tableActions]{
    display: none;
  }
}

.rsdt-container table tbody tr{
  cursor: pointer;
}

div.rsdt-paginate{
  @extend .pagination;
  @extend .justify-content-center;

  .rsdt-paginate{
    @extend .page-link;
    padding: 0.5rem 0.75rem;
  }

  .rsdt-paginate.first {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }  

  .rsdt-paginate.last {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .rsdt-paginate.current {
    z-index: 1;
    color: #fff;
    background-color: $primary;
    border-color:  $primary;
  }

  .page-link:focus, div.rsdt-paginate .rsdt-paginate:focus {
    box-shadow: none;
  }  
}




.smooth-dnd-draggable-wrapper article,
.smooth-dnd-ghost div{
  cursor: move;
  &:hover{
    cursor: move;
  }
  &:active{
    cursor: move;
  }
}

.main.page-tasks {
  //background-color: $primary;
  background-color: theme-color-level(light, 0.3);
  display: flex;
  flex-direction: column;
  position: relative;

  .container-fluid{
    flex: 1;
    .animated{
      height: 100%;
    }
  }

  nav .breadcrumb{
    margin-bottom: 15px;
  }

  .container-fluid {
    padding: 0 15px;
  }
  
  .react-trello-board{
    padding: 0;
    margin: 0 -5px;
    background: transparent;
    //max-width: 100%;
    height: 100%;
    & > div{
      height: 100%;
      & > .smooth-dnd-container{
        height: 100%;
        & > span{
          display: inline-block;
          height: 100%;
        }
      }
    }

    .react-trello-card{
      .cardWrapper{
        @extend .card;
        margin-bottom: 0;
        border-bottom: none;
        border-radius: 3px;
        transition: all .25s ease;
        opacity: 1;
  
      }
      //overflow: visible;
      .card-body {
        padding: .75rem;
        .dropdown-menu.show{
          left: auto !important;
          right: -7px !important;
        }
      }
      .card-footer{
        padding: 0.50rem 0.75rem;
      }
    }
    
    &.filteredBoard .react-trello-card{
      //opacity: 0.25;
      display: none;
      &.filteredCard{
        //opacity: 1;
        display: block;
      }
  }

  }

  .smooth-dnd-container.horizontal{
    flex-wrap: wrap;
    white-space: inherit;
  }

  .smooth-dnd-container.vertical{
    min-height: 150px;
    border: 3px solid transparent;
    margin: -3px;
  }

  .smooth-dnd-container.vertical:empty{
    border: dashed 3px theme-color-level(light, 1.5) !important;
    background: rgba(0,0,0,0.03) !important;
  }

  //.react-trello-dnd-ondrag .react-trello-lane > div:hover {
  .react-trello-dnd-ondrag .react-trello-lane > div:hover .smooth-dnd-container.vertical {
    border: dashed 3px theme-color-level(light, 1.5) !important;
    background: rgba(0,0,0,0.03) !important;
  }

  .react-trello-lane {
    padding: 0 5px;
    margin: 0 5px;
    //background-color: theme-color-level(light, 0.3);
    background-color: transparent;
    height: 100%;
    max-height: 100%;

    h5{
      border-bottom: 4px solid $primary; 
    }


    & > div{
      min-width: 250px;
      //max-height: none;
      //min-height: 150px;
      //padding-bottom: 150px !important;
      //overflow: visible;
      position: relative;
      height: 70vh;
    }
  }
  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper{
    overflow: visible;
  }

  /*.smooth-dnd-stretcher-element{
    background: red !important;
  }*/
}


.react-date-picker{
  width: 100%;
  .react-date-picker__wrapper{
    @extend .form-control;
    display: flex;

    .react-date-picker__button{
      padding-top: 0;
      padding-bottom: 0;
    }
    .react-date-picker__inputGroup__input:invalid {
      background: none;
    }    
  }
}
.smooth-dnd-draggable-wrapper{
  .column-drag-handle{
    cursor: ns-resize;
  }
}

.opacity-ghost {
  transition: all .18s ease;
  opacity: 0.8;
  background-color: #fff;
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  cursor: ns-resize;
  padding: 5px 0;
}

.opacity-ghost-drop {
  opacity: 1;
  background-color: white;
  box-shadow: 0px 0px 3px  rgba(0,0,0,0.0);
}

.search-task-btn{
  position: absolute;
  top: 1px;
  right: 1rem;
  z-index: 100;
}

textarea{
  min-height: 50px;
}

button.disabled:not(.btn-outline-primary){
  cursor: not-allowed;
  &:hover::after{
    content: ' (Formulaire non valide)';
  }
}

button.disabled.btn-outline-primary{
  cursor: not-allowed;
  &:hover::after{
    content: ' (Formulaire inchangé)';
  }
}

.search-box-overlay{
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
  border-radius: 0.3rem;
  margin-top: 10px;
  .form-control-lg{
    font-size: 2rem;
    line-height: 2.5;
    height: calc(4rem + 2px);
  }
}

.search-box-form{
  .form-check-inline{
    margin-left: 20px;
    .custom-checkbox{
      margin: 0 10px;
    }
  }
}

.close-search-overlay{
  position: absolute;
  top: 55px;
  right: 25px;
}

.loader-overlay{
  position: absolute;
  height: 100% !important;
  width: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
}

@include media-breakpoint-down(xs) { 
  .main .container-fluid {
    padding: 0 15px;
  }

  .main.page-tasks{

    .react-trello-board{
      margin: 0;
      width: 100%;
      & > .smooth-dnd-container{
        width: 100%;
        & > span{
          width: 100%;
          display: block;
        }
      }
    }
    .react-trello-lane{
      width: 100%;
      margin: 5px 0;
      & > div{
        width: 100%;
      }
  
      .react-trello-card{
        max-width: 100%;
      }
    }
  } 

}
